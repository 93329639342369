<template>
  <div>
    <SaveRecipeForm />
  </div>
</template>

<script>
import SaveRecipeForm from '@/components/SaveRecipeForm'

export default {
  components: {
    SaveRecipeForm
  }
}
</script>

<style lang="scss" scoped></style>
